import type { Project } from '../../aansluiting/project/types'
import type { AuthenticatedUser } from '../../auth/types'
import type { HoofdleidingProject } from '../../hoofdleiding/projecten/types'
import type { GroupedOption } from '../../projectdossier/types'

export const getPossibleAssigneesForProject = ({
  project,
}: {
  project: Project | HoofdleidingProject
}) => {
  const { combi, coordAannemer, netbeheerdersContract, aannemers } = project
  const buildOption = (group) => (label) => ({ group, label })

  return (combi ? [buildOption('Combi')(combi)] : [])
    .concat(coordAannemer?.map(buildOption('Coordinerende')) || [])
    .concat(netbeheerdersContract?.map(buildOption('Netbeheerder')) || [])
    .concat(aannemers?.map(buildOption('Aannemer')) || [])
}

export const canCreateAssignedBijlageForProject = ({
  project,
  user,
  assignees,
}: {
  project: Project | HoofdleidingProject
  user: AuthenticatedUser | Record<string, any>
  assignees: GroupedOption[]
}): boolean => {
  const allowedStatusTextAssignedBijlage = [
    'Intake',
    'Werkvoorbereiding',
    'In uitvoering',
  ]
  const { type, statusText } = project
  const isHoofdleidingProject = type === 'Hoofdleiding'
  const isPossibleAssignee = assignees.some(
    (option: GroupedOption) => option.label === user.organisatie,
  )

  if (!user.canCreateAssignedBijlage) return false
  if (isHoofdleidingProject)
    return project.verantwoordelijken.includes(user.organisatie)

  return (
    isPossibleAssignee && allowedStatusTextAssignedBijlage.includes(statusText)
  )
}

export const canChangeProjectForProject = ({
  project,
  user,
}: {
  project: Project | HoofdleidingProject
  user: AuthenticatedUser | Record<string, any>
}): boolean => {
  const { type, verantwoordelijken } = project
  const isHoofdleidingProject = type === 'Hoofdleiding'

  return (
    isHoofdleidingProject &&
    !!user.canChangeProject &&
    verantwoordelijken.includes(user.organisatie)
  )
}

export const explodeKnownProjects = (
  linkedProjects: string[] | undefined, // can be undefined, so this is to ensure typing matches that
  accessProjectList: Project[],
) => {
  if (!linkedProjects || !linkedProjects.length) return []

  return linkedProjects.map(
    (id) =>
      accessProjectList.find((x) => x.aanvraagID === id) || { aanvraagID: id },
  )
}

export const isUserVerantwoordelijkForProject = ({
  user,
  project,
}: {
  user: AuthenticatedUser | Record<string, any>
  project: Project | HoofdleidingProject
}) => {
  return project.verantwoordelijken.includes(user.organisatie)
}

export const updatableProps = [
  'gemeente',
  'naam',
  'typeWerk',
  'externeReferentie',
  'adres',
  'tracelengte',
  'woningen',
  'appartementen',
  'aanvraagDatum',
]

export const buildProjectDetailsUpdates = <T = Project | HoofdleidingProject>(
  baseProject: Partial<T>,
  updatedProject: Partial<T>,
): Partial<T> => {
  return Object.keys(baseProject).reduce((acc, key) => {
    if (updatableProps.includes(key)) {
      if (baseProject[key] !== updatedProject[key]) {
        acc[key] = updatedProject[key]
      } else {
        acc[key] = baseProject[key]
      }
    }

    return acc
  }, {})
}

const propLabelMap: Partial<Record<keyof HoofdleidingProject, string>> = {
  aanvraagDatum: 'Aanvraagdatum',
  adres: 'Adres',
  externeReferentie: 'Externe referentie',
  gemeente: 'Gemeente',
  naam: 'Naam',
  typeWerk: 'Type werk',
  tracelengte: 'Tracelengte',
  woningen: 'Woningen',
  appartementen: 'Appartementen',
}

/** Ondersteunt nog niet alle (hoofdleiding) project properties. Graag toevoegen
 * wanneer nodig.
 */
export const getProjectLabel = (
  key: keyof Project | keyof HoofdleidingProject,
): string => propLabelMap[key] || key
